<template>
    <div class="home-index-page">
        <section class="home-index-page__slider" :class="{ 'no-auth': variant !== 'megadescuentos' }">
            <h1 class="container my-3 text-2xl font-semibold text-gray-800">
                {{ $lang.views.homepage.best_week_offers }}
            </h1>
            <WidgetSlider
                :class="variant === 'megadescuentos' ? 'h-[353px] lg:h-[401px]' : 'h-[251px] lg:h-[295px]'"
            >
                <WidgetSliderItem v-for="(item, key) in pageData?.page.data.card_featured_offers" :key="key">
                    <DCardVertical
                        :info="item"
                        :lazy-image="key > 1"
                        :no-auth="variant !== 'megadescuentos'"
                    />
                </WidgetSliderItem>
            </WidgetSlider>
        </section>

        <div class="home-index-page__content">
            <main class="main-content">
                <section class="main-content__discount-list">
                    <h2>
                        {{ $lang.views.homepage.best_offers_and_coupons_from }}
                        {{ currentDates.month }}
                        {{ currentDates.year }}
                    </h2>
                    <MiscInfiniteScrollPagination
                        v-if="initialRoutePage && initialRoutePage > 1"
                        :meta="infiniteScrollMeta.meta"
                        @on-paginate="onPaginateHandler"
                    ></MiscInfiniteScrollPagination>
                    <MiscInfiniteScroll
                        :page="1"
                        :stop="infiniteScrollMeta.stop"
                        :loading="infiniteScrollMeta.loading"
                        @on-next-page="loadDiscounts"
                    >
                        <div class="flex flex-col">
                            <template v-if="pageData.page.cta_cards && pageData.page.cta_cards.length">
                                <CTAHandler
                                    v-for="(cta, key) in pageData.page.cta_cards"
                                    :key="'cta-' + key"
                                    :style="{ order: cta.position }"
                                    class="mb-3"
                                    :cta="cta"
                                />
                            </template>

                            <template v-for="(item, key) in infiniteScrollMeta.data" :key="key">
                                <div :style="{ order: key + 1 }" class="mb-3">
                                    <DCardAdmin v-if="item.is_admin" :info="item" :show-brand-logo="true" />
                                    <DCardMain v-else :info="item" in-stores-route="true" />
                                </div>
                                <MiscInfiniteScrollMarker
                                    :style="{ order: key + 1 }"
                                    v-if="(key + 1) % 15 === 0"
                                    :page="
                                        initialRoutePage
                                            ? Number(initialRoutePage) - 1 + (key + 1) / 15
                                            : (key + 1) / 15
                                    "
                                />
                            </template>
                        </div>
                    </MiscInfiniteScroll>
                </section>
            </main>
            <aside class="sidebar-content">
                <!-- <SideBanners :banner-pos="[1, 2]" /> -->

                <SideCoupons :discounts="featuredCoupons" />

                <SideChip
                    :title="$lang.views.homepage.popular_stores"
                    :path="$lang.routes.brands"
                    :info="featuredStores"
                    mode="stores"
                />

                <SideChip
                    :title="$lang.views.homepage.top_categories"
                    :path="$lang.routes.categories"
                    :info="featuredCategories"
                    mode="categories"
                />
            </aside>
        </div>
    </div>
    <Teleport to="#teleports">
        <MiscInfiniteScrollFooter
            :meta="infiniteScrollMeta.meta"
            @on-paginate="onPaginateHandler"
            :initial-page="initialRoutePage"
        >
            <template v-slot:pagination>
                <MiscInfiniteScrollPagination
                    :meta="infiniteScrollMeta.meta"
                    @on-paginate="onPaginateHandler"
                ></MiscInfiniteScrollPagination>
            </template>
        </MiscInfiniteScrollFooter>
    </Teleport>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api, ServicesQueryParameters } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'
import type { Models } from '~/types/models'
import type { Helpers } from '~/types/helpers'

const {
    public: { origin, siteName, variant },
} = useRuntimeConfig()

const { currentDate } = useDateFunctions()

const currentDates = currentDate()
const { $lang } = useNuxtApp()
const Route = useRoute()
const AuthStore = useAuthStore()
const RootStore = useRootStore()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const headers = buildHeaders(AuthStore.SessionToken)

const initialRoutePage = Number(Route.query.page)

const infiniteScrollMeta = reactive({
    loading: false,
    stop: false,
    meta: {
        current_page: initialRoutePage ? initialRoutePage : 1,
    } as Helpers.Meta,
    data: [] as Models.Discount[],
})

const loadDiscounts = async () => {
    infiniteScrollMeta.loading = true

    const result = await $fetch<Api.Responses.General.IndividualModel<Models.Discount>>(
        endpoints.general.discounts.path,
        {
            baseURL,
            headers,
            params: {
                page: infiniteScrollMeta.meta.current_page + 1,
                per_page: 15,
                home_discounts: true,
                ...(Route.path === '/' + $lang.routes.commented
                    ? { order_by: 'most-comment' }
                    : Route.path === '/' + $lang.routes.news
                      ? { order_by: 'latest' }
                      : {}),
            } as ServicesQueryParameters.GetDiscounts,
        },
    )

    if (result?.feedback === 'data_success') {
        infiniteScrollMeta.data = [...infiniteScrollMeta.data, ...result.data.results]

        infiniteScrollMeta.meta = result.data.meta

        if (
            !infiniteScrollMeta.meta.last_page ||
            infiniteScrollMeta.meta.current_page === infiniteScrollMeta.meta.last_page
        ) {
            infiniteScrollMeta.stop = true
        }
    }

    infiniteScrollMeta.loading = false
}

const onPaginateHandler = (page: number) => {
    location.search = 'page=' + page
}

const { data: homepageData, error: errorPage } = await useAsyncData('homepage-data', async () =>
    $fetch<Api.Responses.Pages.Home | Api.Responses.ResourceNotFound>(endpoints.pages.home.path, {
        method: 'GET',
        headers,
        baseURL,
    }),
)

if (errorPage.value || !homepageData.value || homepageData.value.feedback !== 'data_success') {
    throw createError({
        statusCode: homepageData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            homepageData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const { data: homepageDataDiscounts, error: errorDiscounts } = await useAsyncData(
    'homepage-data-discounts',
    async () =>
        $fetch<Api.Responses.General.IndividualModel<Models.Discount>>(endpoints.general.discounts.path, {
            baseURL,
            headers,
            params: {
                page: infiniteScrollMeta.meta.current_page,
                per_page: 15,
                home_discounts: true,
                ...(Route.path === '/' + $lang.routes.commented
                    ? { order_by: 'most-comment' }
                    : Route.path === '/' + $lang.routes.news
                      ? { order_by: 'latest' }
                      : {}),
            } as ServicesQueryParameters.GetDiscounts,
        }),
)

if (
    errorDiscounts.value ||
    !homepageDataDiscounts.value ||
    homepageDataDiscounts.value.feedback !== 'data_success'
) {
    throw createError({
        statusCode: homepageDataDiscounts.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            homepageDataDiscounts.value?.feedback === 'resource_not_found'
                ? 'Página no encontrada'
                : 'Algo salió mal',
    })
}

const pageData = homepageData.value
const initialDiscounts = homepageDataDiscounts.value

infiniteScrollMeta.data = initialDiscounts.data.results
infiniteScrollMeta.meta = initialDiscounts.data.meta

const featuredCoupons = computed(() => {
    return RootStore.layoutData?.data.sidebar.coupons || []
})
const featuredStores = computed(() => {
    return RootStore.layoutData?.data.sidebar.stores || []
})
const featuredCategories = computed(() => {
    return RootStore.layoutData?.data.sidebar.categories || []
})

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [pageData.page.title || '', pageData.page.description || ''],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
    ...(Route.path !== '/' ? { robots: 'noindex' } : {}),
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

useJsonld({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'WebSite' as 'WebSite',
            name: $lang.brand,
            url: origin,
        },
        {
            '@type': 'Organization' as 'Organization',
            name: $lang.brand,
            url: origin,
            brand: $lang.brand,
            logo: `${origin}/assets/schema.org/organisation/${variant}.png`,
            email: $lang.views.homepage.schemeOrg.email,
            founder: 'Juan Hurtado',
            sameAs: $lang.views.homepage.schemeOrg.sameAs,
            address: $lang.views.homepage.schemeOrg.address,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.home-index-page {
    &__slider {
        @apply container mb-5;
    }
    &__content {
        @apply container mb-3 xl:flex xl:space-x-5;

        .main-content {
            @apply xl:order-1 xl:flex-grow;

            &__discount-list {
                h2 {
                    @apply mb-4 text-2xl font-semibold text-gray-800;
                }
            }

            .paragraph-with-image {
                @apply my-5 block justify-between pb-8 md:flex;
                &__paragraph {
                    @apply w-full text-sm text-gray-800 md:w-1/2;
                    h2 {
                        @apply flex items-center text-lg font-semibold text-site-primary;
                        img {
                            @apply h-4;
                        }
                    }
                    div {
                        @apply space-y-2;
                        span {
                            @apply text-lg text-site-primary;
                        }
                    }
                }
                &__image {
                    @apply mx-auto mt-2 h-64 self-center rounded-lg object-contain sm:mt-0;
                }
            }

            &__server-content {
                @apply mt-5 rounded-lg bg-white px-2 py-3 md:px-4 md:py-6 xl:px-8 xl:py-10;

                > div {
                    @apply prose-sm prose-gray prose-headings:font-semibold prose-p:text-sm prose-ol:list-decimal prose-ul:list-disc prose-li:marker:font-semibold prose-li:marker:text-site-primary;

                    @apply prose-headings:mt-0 prose-p:my-0 !important;
                }
            }

            &__subtitle {
                @apply mb-2 flex items-baseline space-x-2 pt-4;
                h2 {
                    @apply flex-none text-2xl font-semibold text-gray-800;
                }

                a {
                    @apply block border-l border-gray-300 pl-2 text-sm text-site-primary;
                }
            }
        }
        .sidebar-content {
            @apply mt-4 space-y-5 xl:order-2 xl:mt-0 xl:w-80 xl:flex-none xl:pt-0;
        }
    }
}
</style>
